@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url("https://cdn.jsdelivr.net/npm/@mdi/font@6.9.96/css/materialdesignicons.min.css");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css");

@import "./assets/css/font.css";
@import "./assets/css/login.css";
@import "./assets/css/header.css";
@import "./assets/css/sidenav.css";
@import "./assets/css/manageSubClient.css";
@import "./assets/css/landing.css";
@import "./assets/css/swal.css";
@import "./assets/css/pagination.css";
@import "./assets/css/footer-inner.css";
@import "./assets/css/splash-screen.css";

body {
    margin: 0;
    font-family: "Helvetica", "Arial", "Liberation Sans", "Noto Sans", sans-serif;;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #000000; /* Fallback color */
    position: relative;
}

body::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(./assets/images/bg-img.png) no-repeat center center;
    background-size: cover;
    opacity: 0.25;
    z-index: -1;
    pointer-events: none;
}

.content {
    position: relative;
    z-index: 1;
    flex: 1;
    width: 100%;
    min-height: 100vh;
    overflow-x:hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

* {
    font-family: "Helvetica", "Arial", "Liberation Sans", "Noto Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
}

btn {
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}

.outer-app-content-cvr {
    min-height: 100%;
    position: relative;
    z-index: 1;
}
