/*
#body-row {
    margin-left:0;
    margin-right:0;
}

#sidebar-container {
    min-height: 100vh;
    background-color: transparent;
    padding: 0;
    border-right: 1px solid #373A42;
}

!* Sidebar sizes when expanded and expanded *!
.sidebar-expanded {
    width: 260px;
}
.sidebar-collapsed {
    width: 60px;
}

!* Menu item*!
#sidebar-container .list-group a {
    height: 50px;
    color: #8F9095;
}

!* Submenu item*!
#sidebar-container .list-group .sidebar-submenu a {
    height: 45px;
    padding-left: 30px;
}
.sidebar-submenu {
    font-size: 0.9rem;
}

!* Separators *!
.sidebar-separator-title {
    !*background-color: #333;*!
    height: 35px;
}
.sidebar-separator {
    !*background-color: #333;*!
    height: 25px;
}
.logo-separator {
    !*background-color: #333;    *!
    height: 60px;
}

!* Closed submenu icon *!
#sidebar-container .list-group .list-group-item[aria-expanded="false"] .submenu-icon::after {
    content: " \f0d7";
    font-family: FontAwesome;
    display: inline;
    text-align: right;
    padding-left: 10px;
}
!* Opened submenu icon *!
#sidebar-container .list-group .list-group-item[aria-expanded="true"] .submenu-icon::after {
    content: " \f0da";
    font-family: FontAwesome;
    display: inline;
    text-align: right;
    padding-left: 10px;
}

#body-col {
    background-color: #333;
}

.list-group-item{
    background-color: transparent !important;
}

.gradient-border {
    width: 110px;
    display: inline-block;
    border-radius: 50%; !* Makes the border and the image circular *!
    padding: 2px; !* Space for the border *!
    background: linear-gradient(90deg, #d1b9b90d 1%, #cfaeae1f 14%, #ff161633 17%, #ff1616f2 100%); !* Gradient border *!
}

.client-img {
    width: 100%;
    height: auto;
    border-radius: 50%;
    display: block;
    padding:3px;
    background: #000;
}

.user-container {
    padding: 30px 0;
}

.material-symbols-outlined{
    font-size: 22px !important;
}
*/

.logo-container img.full-logo {
    width: 120px;
    height: auto;
}

.logo-container img.collapsed-logo {
    width: 40px;
    height: auto;
}

.user-profile {
    padding: 0 0 16px 0;
    background-color: transparent;
}
/*power/notification/setting*/
.set-not-power-icon {
    width: 1.2em !important;
    height: 1.2em !important;
}
.notification-settings-icon {
    color: #8F9095;
}

.notification-settings-icon:hover{
    text-decoration: none !important;
    color: #fff;
}

.active-icon {
    color: #fff;
}

.notification-settings-icon .badge {
    position: absolute;
    top: 0px;
    right: 0;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    /*padding: 2px 6px;*/
    font-size: 10px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-info {
    margin-left: 8px;
}

.small-avatar {
    width: 30px;
    height: 30px;
}

.full-avatar {
    width: 50px;
    height: 50px;
}

.menu-item {
    color: #8F9095;
}

.menu-item.active {
    color: #fff;
}

#sidebar-container {
    /*margin-top: 70px;
    max-width: 20%;
    background-color: transparent !important;
    padding: 0;*/

    margin-top: 70px;
    max-width: 260px;
    height: 100%;
    padding: 0;
    position: fixed;
    z-index: 1;
    background: transparent;
}
.sidebar-collapsed .css-ewdv3l{
    background: #000000;
}
.sidebar-collapsed .css-1t8x7v1 >.ps-menu-button{
    height: 40px;
}
.sidebar-collapsed .submenu-content-link .ps-menu-button{
    padding-left: 10px !important;
}
.sidebar-collapsed .submenu-content-link .ps-menu-button a{
    padding: 0 !important;
}

@media screen and (min-width: 611px) and (max-width: 991px) {
    #sidebar-container {
        margin-top: 126px;
    }
}

@media screen and (min-width: 200px) and (max-width: 610px) {
    #sidebar-container {
        margin-top: 93px;
    }
}

.ps-sidebar-container{
    background-color: transparent !important;
}

.ps-sidebar-root{
    border-right: 1px solid #373A42 !important;
    min-height: 100%;
}

.gradient-border {
    width: 100px;
    height: 100px;
    display: inline-block;
    border-radius: 50%;
    padding: 2px;
    background: linear-gradient(#c24d4d, #6c73cd) padding-box, linear-gradient(to bottom, #242424, #242424) border-box;
    /*background: linear-gradient(90deg, #d1b9b90d 1%, #cfaeae1f 14%, #ff161633 17%, #ff1616f2 100%);*/
}

.gradient-border-collapse {
    width: 50px;
    height: 50px;
    display: inline-block;
    border-radius: 50%;
    padding: 2px;
    background: linear-gradient(#c24d4d, #6c73cd) padding-box, linear-gradient(to bottom, #242424, #242424) border-box;
    /*background: linear-gradient(90deg, #d1b9b90d 1%, #cfaeae1f 14%, #ff161633 17%, #ff1616f2 100%);*/
}

.client-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    display: block;
    padding:3px;
    background: #000;
}

.client-img-collapse {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
    display: block;
    padding:3px;
    background: #000;
}

.ps-menu-button{
    color: #8F9095;
}

.ps-menu-button:hover{
    /*background: transparent !important;*/
    background-color: #1b1b1b !important;
    /*border-right: 2px solid #b75c79 !important;*/
    color: #fff !important;
}

.ps-menu-button:active {
    color: #fff !important;
}

.ps-menu-button-active {
    /*background: transparent !important;*/
    background-color: #1b1b1b !important;
    border-right: 2px solid #b75c79 !important;
    color: #fff !important;
}

.submenu-content-link .ps-menu-button {
     padding-left: 34px;
}

.ps-menu-label {
    color: #8F9095;
    text-decoration: none !important;
}

.ps-menu-label:hover {
    text-decoration: none !important;
    color:#fff !important;
}

.ps-menu-label:active {
    color:#fff !important;
}

.ps-menu-button-active a {
    color: #fff !important;
    text-decoration: none !important;
}

.ps-submenu-content {
    background-color: transparent !important;
}

.ps-submenu-expand-icon {
    color: #8F9095;
    margin-top: -7px;
}

/*@media screen and (max-height: 500px) {
    .sidebar-content {
        max-height: 240px;
        overflow-y: auto;
    }
}*/

.sidebar-content {
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
}

@media (min-height: 1080px) {
    .sidebar-content {
        min-height: 500px !important;
    }
}

.sidebar-content::-webkit-scrollbar {
    width: 5px;
}

.sidebar-content::-webkit-scrollbar-thumb {
    background-color: #3a3a3a;
    border-radius: 5px;
}

.sidebar-content::-webkit-scrollbar-thumb:hover {
    background-color: #545454;
    cursor:pointer;
}

.sidebar-content::-webkit-scrollbar-track {
    background-color: transparent;
}

.css-vj11vy.ps-menu-root:has(.ps-menu-button.ps-open)::-webkit-scrollbar {
    width: 5px;
}

.css-vj11vy.ps-menu-root:has(.ps-menu-button.ps-open)::-webkit-scrollbar-thumb {
    background-color: #2C2C2CFF;
    border-radius: 5px;
    border-radius: 5px;
}

.css-vj11vy.ps-menu-root:has(.ps-menu-button.ps-open)::-webkit-scrollbar-thumb:hover {
    background-color: #4a4a4a;
}

.css-vj11vy.ps-menu-root:has(.ps-menu-button.ps-open)::-webkit-scrollbar-track {
    background-color: transparent;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium button{
    float: right;
}

.ps-submenu-content {
    z-index: 1000;
}

/**/
.fixed-sidebar {
    height: 100%;
}
.has-sidebar {
    flex-direction: row;
}
.outer-app-content-cvr {
    z-index: 1;
}
.outer-app-content-cvr {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
}
.sidebar ~ .layout {
    height: 100%;
    overflow: auto;
}
.fixed-sidebar .sidebar {
    height: 100%;
    overflow: auto;
}
.sidebar {
    width: 250px;
    min-width: 250px;
    transition: width, left, right, 0.3s;
}
.sidebar {
    color: #7d84ab;
    position: relative;
    height: 100%;
    max-height: 100%;
    overflow: hidden !important;
}
.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 99;
    display: none;
}
.sidebar-collapser {
    -webkit-transition: left, right, 0.3s;
    transition: left, right, 0.3s;
    position: fixed;
    left: calc(250px - 30px);
    top: 80px;
    padding: 15px 15px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #4a4a4a;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 1.2em;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    z-index: 111;
    cursor: pointer;
    color: white;
    -webkit-box-shadow: 1px 1px 4px #0c1e35;
    box-shadow: 1px 1px 4px #0c1e35;
}
.sidebar-collapsed .ps-collapsed .sidebar-collapser {
    left: calc(80px - 30px);
}
.sidebar-collapser {
    -webkit-transition: left, right, 0.3s;
    transition: left, right, 0.3s;
    position: fixed;
    left: calc(250px - 30px);
    top: 80px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #4a4a4a;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 1.2em;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    z-index: 111;
    cursor: pointer;
    color: white;
    -webkit-box-shadow: 1px 1px 4px #0c1e35;
    box-shadow: 1px 1px 4px #0c1e35;
}


.sidebar-layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    z-index: 2;
    max-height: 100vh;
    min-height: 100%;
    transition: 0.3s;
    -webkit-overflow-scrolling: touch !important;
    /*overflow-y: auto;*/
    /*overflow-x: hidden;*/
    /*scrollbar-width: thin; !* Firefox *!*/
    /*scrollbar-color: #2c2c2c transparent; !* Firefox *!*/
}
/*@media screen and (max-height: 600px) and (max-width: 1000px){
    .sidebar-expanded .sidebar-layout {
        !*overflow-x: hidden;*!
        transition: 0.3s;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        white-space: nowrap;
        height: 330px;
        !*overflow-y: auto;*!
        -webkit-overflow-scrolling: touch;
    }
}

@media screen and (max-height: 600px) and (max-width: 1300px){
    .sidebar-expanded .sidebar-layout {
        !*overflow-x: hidden;*!
        transition: 0.3s;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        white-space: nowrap;
        height: 100%;
        !*overflow-y: auto;*!
        -webkit-overflow-scrolling: touch;
    }
}

@media screen and (min-height: 1301px) {
    .sidebar-expanded .sidebar-layout {
        !*overflow-x: hidden;*!
        transition: 0.3s;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        white-space: nowrap;
        height: 100%;
        !*overflow-y: auto;*!
        -webkit-overflow-scrolling: touch;
    }
}*/

.css-dip3t8{
    /*overflow-y: auto !important;*/
}
.css-dip3t8 {
    overflow-y: hidden !important;
}
@media screen and (min-height: 601px) and (min-width: 992px){
    .css-vj11vy.ps-menu-root:has(.ps-menu-button.ps-open){
        height: auto;
        /*max-height: 300px !important;*/
        overflow-y: auto;
    }
}


.sidebar-layout::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
}

.sidebar-layout::-webkit-scrollbar-thumb {
    background-color: #2C2C2CFF;
    border-radius: 5px;
    border-radius: 5px;
}

.sidebar-layout::-webkit-scrollbar-thumb:hover {
    background-color: #4a4a4a;
}

.sidebar-layout::-webkit-scrollbar-track {
    background-color: transparent;
}

.sidebar.has-bg-image .image-wrapper {
    display: block;
}
sidebar .image-wrapper {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: none;
}
.sidebar .image-wrapper > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.sidebar.has-bg-image .sidebar-layout {
    background-color: rgba(12, 30, 53, 0.85);
}
.sidebar .sidebar-layout {
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #0c1e35;
    z-index: 2;
}
.sidebar-layout .sidebar-header {
    max-height: 195px;
    /*min-height: 100px;*/
    display: flex;
    align-items: center;
    padding: 0 20px;
    background-color: transparent;
    justify-content: space-between;
}
.sidebar .pro-sidebar-logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.sidebar .menu .menu-item.sub-menu {
    position: relative;
}

@media screen and (max-height: 600px){
    /*.css-vj11vy.ps-menu-root:has(.ps-menu-button.ps-open){
        max-height: 100%;
        overflow-y: hidden;
    }*/
    .sidebar-expanded .css-i4bv87-MuiSvgIcon-root{
        font-size: 20px;
    }
    .css-16jesut >.ps-menu-button{
        height: 40px;
        font-size: 14px;
    }
    .ps-menu-label{
        font-size: 14px;
    }
    .sidebar-expanded::-webkit-scrollbar {
        width: 5px;
    }

    .sidebar-expanded::-webkit-scrollbar-thumb {
        background-color: #3a3a3a;
        border-radius: 5px;
    }

    .sidebar-expanded::-webkit-scrollbar-thumb:hover {
        background-color: #545454;
    }

    .sidebar-expanded::-webkit-scrollbar-track {
        background-color: transparent;
    }
    .gradient-border {
        width: 70px;
        height: 70px;
    }
}

.name-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 90%;
}
