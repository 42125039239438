.refresh-button {
    background: none; /* Removes the default button background */
    border: none; /* Removes the default button border */
    cursor: pointer; /* Shows a pointer cursor when hovering */
    padding: 0; /* Removes any padding */
    color: inherit; /* Inherits the text color from the parent element */
    transition: color 0.2s; /* Smooth color transition on hover */
}

.refresh-button:hover {
    color: #007bff; /* Change color on hover (optional) */
}

.refresh-button svg {
    font-size: 24px; /* Adjust the size of the icon */
}

.rotating svg {
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
