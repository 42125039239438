.rc-pagination{
    display: flex;
    list-style: none;
}

.pagination-data {
    padding: 7px 0 !important;
    margin: 0px 0 !important;
    border-top: none !important;
}

.rc-pagination-total-text {
    line-height: 2;
}

.pagination-data li {
    padding: 0 5px;
}

.pagination-container li button {
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 20px !important;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.pagination-container li a {
    border: none;
    cursor: pointer;
    width: 25px;
    justify-content: center;
    display: flex;
    height: 25px;
    line-height: 2;
    border-radius: 20px !important;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}
