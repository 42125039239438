.header {
    width: 100%;
    background-color: #000;
    border-bottom: 1px solid #373A42;
    z-index: 1001;
    position: fixed;
    box-shadow: 0 1px 10px #222;
}

.header-left {
    display: flex;
    align-items: center;
}

.header-logo {
    height: 30px;
    margin: 15px 1px 15px 0px;
}

@media screen and (min-width: 200px) and (max-width: 610px) {
    .icatch-logo-container {
        display: none;
    }
}

.icatch-group-logo {
    height: 38px;
    margin: 15px 1px 15px 0px;
}

.header-logo-inside {
    height: 27px;
    margin: 15px 24px 15px 12px;
}

.divider {
    height: 70px;
    width: 0.8px;
    background-color: #373A42;
    margin: 0 1rem;
}

@media screen and (min-width: 200px) and (max-width: 991px) {
    .divider {
        display:none;
    }
    .header-right{
        border-top: 1px solid #373A42;
    }
}

.header-right {
    width: 100%;
    margin-right: auto;
    display: flex;
    align-items: center;
    margin-left: auto;
    color: #fff;
}

.header-link {
    color: #fff; /* Customize link color */
    text-decoration: none;
    font-weight: 200;
    font-size: 15px;
}

.header-link:hover {
    color: #fff; /* Customize link color */
    text-decoration: none;
}

.navbar-toggler {
    border: none; /* Remove default border */
}

.link_icon {
    color: #fff;
    margin-right: 10px;
}

.toggle-switch {
    width: 100px;
    height: 30px;
    margin-top: 2px;
    background-color: #828282;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
}

.slider {
    width: 50%;
    height: 100%;
    background-color: #fff;
    border-radius: 15px;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #828282;
    font-size: 14px;
    font-weight: 500;
}

.slider.active {
    background-color: #47aa45;
    transform: translateX(100%);
    color: #fff;
}

.slider-text {
    display: block;
    width: 100%;
    text-align: center;
}

.support-header-icon {
    padding: 5px 5px;
    cursor: pointer;
}

.support-header-icon:hover {
    border-radius: 50%;
    background-color: #4a4a4a;
}

.api-doc-cover {
    cursor: pointer;
    padding: 5px 10px;
    margin-right: 15px;
}

.api-doc-cover:hover {
    border-radius: 35px;
    margin-right: 15px;
    background-color: #4a4a4a;
}
