body {
    font-family: "Helvetica",Sans-serif;
}

body::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    background-size: cover;
    opacity: 0.25;
    z-index: -1;
    pointer-events: none;
}

/*landing header*/

.header-landing-left {
    display: flex;
    align-items: center;
}

.header-landing-logo {
    height: 38.2px; /* Adjust as needed */
}

.header-landing-right {
    width: 100%;
    color: #fff;
}
.buttons {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.header-landing-right .buttons {
    opacity: 1;
}

.header-landing {
    /*position: fixed;*/
    width: 100%;
    display: flex;
    padding: 10px 80px;
    background-color: rgba(0, 0, 0, 0.5);
    /*background-color: transparent;*/
    z-index: 1000;
    transition: background-color 0.3s ease;
}

.header-logo-landing {
    height: 28px;
    margin: 0;
}

.header-logo-right {
    height: 44px;
    margin: 0;
}

.header-landing.scrolled {
    background-color: rgba(0, 0, 0, 0.8);
}

.logo {
    color: #fff;
    font-size: 24px;
}

.buttons {
    display: flex;
    gap: 10px;
}

.login-btn, .signup-btn {
    padding: 4px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.login-btn {
    background-color: #b75c79;
    color: white;
    letter-spacing: 0.02857em;
    line-height: 1.75;
}

.login-btn:hover {
    background-color: #9f4663;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    color: white;
}

.continue-btn:active {
    background-color: #943b58;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.2);
    transform: scale(0.95);
}

.login-btn a {
    color: white;
}

.login-btn a:hover {
    text-decoration: none !important;
    color: white;
}

.signup-btn {
    /*background-color: #fff;*/
    /*color: #373737;*/
    background: #ffffff80;
    color: #fff;
}

.signup-btn:hover {
    background-color: #d9d9d9;
    color: #373737;
}

.signup-btn a {
    color: #373737;
}

.signup-btn a:hover {
    text-decoration: none !important;
    color: #373737;
}

/*Banner*/
.banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70vh;
    background: url('../images/bg-img.png') no-repeat center center;
    background-size: cover;
    padding: 20px 80px;
    position: relative;
    background-position: left center !important;
}

.intro-text {
    color: white;
    max-width: 50%;
}

.intro-text h1 {
    font-size: 35px;
    margin-bottom: 20px;
    font-weight: 600;
}

.intro-text p {
    font-size: 18px;
    margin-bottom: 40px;
}

.banner-buttons {
    display: flex;
    gap: 20px;
}

.face-image {
    margin-top: 25px;
}

.face-image-face {
    width: 350px;
}

@media screen and (min-width: 839px) and (max-width: 1101px) {
    .face-image-face {
        width: 300px !important;
    }

    .banner {
        padding-top: 25px !important;
        height: 65vh;
    }
}

@media screen and (min-width: 768px) and (max-width: 838px) {
    .intro-text h1 {
        font-size: 25px;
    }
    .intro-text p {
        font-size: 15px;
    }
    .banner {
        padding-top: 0px !important;
        height: 60vh;
    }
    .face-image-face {
        width: 260px !important;
    }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
    .face-image-face {
        width: 200px !important;
    }
    .intro-text h1 {
        font-size: 20px;
    }
    .intro-text p {
        font-size: 14px;
    }
    .login-btn, .signup-btn {
        padding: 2px 20px;
    }
}

@media screen and (min-width: 200px) and (max-width: 599px) {
    .banner {
        padding-top: 0px;
        height: 40vh;
    }
    .intro-text h1 {
        font-size: 20px;
    }
    .intro-text p {
        font-size: 12px;
    }
    .face-image {
        display: none;
    }
    .intro-text {
        max-width: 100%;
    }
}

/*landing body*/
.main-content {
    padding: 50px 80px;
    background-color: #f8f8f8;
}

.main-content h2 {
    color: #373737;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    display: inline-block;
    position: relative;
    padding: 0 0 38px 0;
}

.main-content h2:after {
    content: '';
    position: absolute;
    top: 72%;
    left: 40%;
    width: 19%;
    height: 5px;
    background-color: #b75c79;
}

@media screen and (min-width: 200px) and (max-width: 624px) {
    .main-content h2:after {
        top: 85%;
    }
}

.main-content p {
    color: #6F6F6F;
    font-size: 15px;
    padding:0;
}

@media (min-width: 1240px) {
    .main-content p {
        font-size: 18px !important;
    }
    .point-authentication-container p {
        font-size: 18px !important;
    }
    .real-time-container p {
        font-size: 18px !important;
    }
    .hrms-container p {
        font-size: 18px !important;
    }
    .ats-container p {
        font-size: 18px !important;
    }
    .integrates-section p {
        font-size: 18px !important;
    }
    .integration-blocks p {
        font-size: 18px !important;
    }
}

.real-time-container h2 {
    color: #373737;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    display: inline-block;
    position: relative;
    padding: 0 0 38px 0;
}

.real-time-container h2:after {
    content: '';
    position: absolute;
    top: 70%;
    left: 40%;
    width: 19%;
    height: 5px;
    background-color: #b75c79;
}

@media screen and (min-width: 487px) and (max-width: 965px) {
    .real-time-container h2:after {
        top: 72%;
    }
}

@media screen and (min-width: 200px) and (max-width: 486px) {
    .real-time-container h2:after {
        top: 82%;
    }
}

.real-time-container p {
    color: #6F6F6F;
    font-size: 15px;
    padding: 0 40px;
}

.point-authentication-container {
    padding: 50px 80px;
    background-color: #141619;
    padding: 50px 80px;
    background-image: url("../images/intersect.png");
    background-position: 0 -21vh;
    background-repeat: no-repeat;
    background-size: cover;

}

.point-authentication-container h2 {
    color: #fff;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    display: inline-block;
    position: relative;
    padding: 0 0 38px 0;
}

.point-authentication-container h2:after {
    content: '';
    position: absolute;
    top: 72%;
    left: 40%;
    width: 19%;
    height: 5px;
    background-color: #fff;
}

.point-authentication-container p {
    color: #fff;
    font-size: 15px;
    padding: 0 40px;
}

.auth-icon{
    color: #fff;
    width: 4em !important;
    height: 4em !important;
}

.multiplatform-container span{
    padding: 0 0 0 15px;
    font-size: 18px;
}

.real-time-container {
    padding: 50px 80px;
    background-color: #EEEEEE;
}

.real-time-icon {
    width: 100px;
    height: 100px;
}

/**/
.integrates-section {
    text-align: center;
    padding: 50px 0 0 0;
    background-color: #f8f8f8; /* Adjust this based on your design */
}

.integrates-section h2 {
    font-size: 30px;
    font-weight: bold;
    color: #373737;
}

.integrates-section-sub-hd {
    font-size: 12px;
    color: #8B8B8B;
    margin-bottom: 40px;
}

.integration-blocks {
    background-color: #EEEEEE;
    margin-top: 92px;
}

.integration-blocks p {
    padding: 0 110px;
    font-size: 15px;
    color: #6F6F6F;
}

.integration-blocks h3 {
    color: #373737;
}

.hrms-container {
    padding: 0 0 0 80px;
    position: relative;
    bottom: 60px;
}

.ats-container {
    padding: 0 80px 0 0;
    position: relative;
    bottom: 60px;
}

@media screen and (min-width: 200px) and (max-width: 768px) {
    .hrms-container {
        padding: 0 !important;
    }

    .ats-container {
        padding: 0 !important;
    }
}

.integration-icon {
    margin-bottom: 20px;
    background: linear-gradient(#242424, #242424) padding-box, linear-gradient(to bottom, #c24d4d, #6c73cd) border-box;
    border-radius: 15px;
    border: 2px solid transparent;
}

/*Landing Footer*/
.landing-footer {
    padding: 50px 80px;
    background-color: #000608;
    color: #fff;
}

.term-privacy-container ul {
    list-style: none;
    display: flex;
    padding-left: 0;
    margin-top: 30px;
}

.term-privacy-container ul li a {
    color: #fff;
    text-decoration: none;
}

.term-privacy-container ul li a:hover {
    text-decoration: none;
}

.term-privacy-container ul li:first-child {
    padding: 0 25px 0 0;
    border-right: 2px solid #fff;
}

.term-privacy-container ul li:last-child {
    padding: 0 0 0 25px;
}

.footer-links li a:hover {
    color: #d0d0d0;
}

.footer-social a:hover{
    color: #d0d0d0;
    text-decoration: none;
}

.footer-social a:hover{
    color: #d0d0d0;
    text-decoration: none;
}

.landing-container {
    /*height: 400px; !* or your desired height *!*/
    overflow-y: auto;
    overflow-x: hidden;
}

.linkedin-icon {
    width: 1.2em;
    height: 1.2em;
}

