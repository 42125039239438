.row.no-gutters {
    margin-right: 0;
    margin-left: 0;
    height: 100vh;
}

.login-left-container {
    background: url(../../assets/images/login_bg.png) no-repeat center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
@media screen and (max-width: 767px){
    .login-left-container{
        display: none !important;
    }
}

.logo-container {
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eval-logo-container {
    margin: 0 0 0 10px;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 200px) and (max-width: 768px) {
    .eval-logo-container {
        height: 25% !important;
    }
}

.login-icatch {
    width: 60%;
    max-width: 250px;
    height: auto;
}

.eval-logo {
    width: 30%;
    max-width: 120px;
    height: auto;
}

/* Right Container Styles */
.login-right-container {
    /*display: flex;
    align-items: center;
    justify-content: center;*/
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    height: 100vh;
}

.form-container {
    padding: 2rem;
    width: 70%;
    margin: auto;
}

.form-container-signup {
    padding: 2rem;
    width: 90%;
    margin: auto;
}

.visit-link {
    text-align: right;
    margin: 12px 10px;
}

.visit-link a {
    font-size: 14px;
    color: #747474;
}

.visit-link a:hover {
    color: #0075F3;
    text-decoration: none;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group button {
    font-size: 14px !important;
}

.form-group-password {
    margin-bottom: 0.1rem;
}

.form-group label {
    font-size: 14px;
    color: #747474;
    margin-bottom: 0;
    font-weight: 500;
}

.form-group .form-control {
    border: 1px solid #E8E8E8;
    font-size: 12px;
}

.forgot-password-link {
    font-size: 12px;
    color: #b75c79;
    font-weight: 600;
    text-decoration: none;
}

.forgot-password-link:hover {
    text-decoration: none;
    color: #9f4663;
}

.forgot-password-link:active {
    color: #943b58;
}

.forgot-password-link primary:focus {
    color: #9f4663;
}

.forgot-password-link:hover {
    text-decoration: none;
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.loin-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 1.5rem 0 0 0;
    border-top: 1px solid #E8E8E8;
    position: relative;
}

.loin-divider span {
    background-color: #fff;
    padding: 0 10px;
    position: relative;
    top: -10px;
    z-index: 1;
    color: #747474;
}

.social-icon{
    border: 1px solid #E8E8E8;
    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.social-logo{
    width: 20px;
    height:20px;
}

.social-login {
    margin-bottom: 12px;
}

.social-login button {
    margin: 0 2rem;
}

.signup-link {
    text-align: center;
    margin-top: 1rem;
    font-size: 14px;
    color: #747474;
}

.signup-link a {
    color: #007bff;
    text-decoration: none;
}

.create-account-hd{
    color: #373737;
    font-weight: 200;
    text-align: center;
    font-size: 28px;
}

.login-link {
    color: #747474;
    font-size: 14px;
    text-align: center;
}

.login-link a{
    color: #b75c79;
    font-size: 14px;
}

.head-container{
    border-bottom:1px solid #E8E8E8;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.accountinfo-hd {
    color: #747474;
    font-size: 15px;
}

.input-group-text {
    padding: 0 .75rem;
}

.pswrd-view-icon {
    width: 0.8em !important;
    height: 0.8em !important;
}

.input-loop {
    padding-left: 0;
}

.input-loop:last-child {
    padding-left: unset;
    padding-right: 0;
}

.auth-code-label {
    color: #747474;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    padding: 0;
}

.prompt-icon {
    color: #E7A343;
    margin-right: 5px;
    vertical-align: middle;
    /*font-size: 40px !important;*/
}


/*
.form-group:first-child {
    border-top: 3px solid #E7A343;
    padding-top: 10px;
}
*/


