@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body,
#root,
.app,
.content {
  width:100%;
  height: 100%;
  font-family: "Helvetica",Sans-serif;
}

.app {
  min-height: 100%;
  display: flex;
 /* position: relative;*/
  flex-direction: row;
}

body {
  margin: 0;
  font-family: "Helvetica",Sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Helvetica",Sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

/*Track*/
::-webkit-scrollbar-track {
  background: #e0e0e0;
}


/*Handle*/
::-webkit-scrollbar-thumb {
  background: #888;
}

/*Handle on Hover*/
::-webkit-scrollbar-track:hover {
  background: #555;
}

