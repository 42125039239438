.outer-main {
    /*margin: 80px 10px 10px 10px;
    min-width: 79%;*/
    transition: width 0.3s ease;
    width: calc(100% - 260px); /* 260px is the expanded width of the sidenav */
    margin-left: 260px;
    margin-top: 80px;
    margin-bottom: 10px;
    margin-right:10px;
}

._loading_overlay_wrapper {
    transition: width 0.3s ease;
    width: calc(100% - 260px); /* 260px is the expanded width of the sidenav */
    margin-left: 260px;
    margin-top: 80px;
    margin-bottom: 10px;
    margin-right:10px;
}

.sidebar-collapsed + .outer-main {
    width: calc(100% - 80px) !important; /* 60px is the collapsed width of the sidenav */
    margin-left: 90px !important;
}

.sidebar-expanded + .outer-main {
    width: calc(100% - 260px); /* 260px is the expanded width of the sidenav */
    margin-left: 260px;
    margin-top: 80px;
}

.sidebar-collapsed + ._loading_overlay_wrapper {
    width: calc(100% - 80px) !important; /* 60px is the collapsed width of the sidenav */
    margin-left: 90px !important;
}

.sidebar-expanded+ ._loading_overlay_wrapper{
    width: calc(100% - 260px); /* 260px is the expanded width of the sidenav */
    margin-left: 260px;
    margin-top: 80px;
}

@media (max-width: 768px) {
    .outer-main {
        width: 100%;
        margin: 5px;
    }
    ._loading_overlay_wrapper {
        width: 100%;
        margin: 5px;
    }
}

@media screen and (min-width: 200px) and (max-width: 991px) {
    .outer-main {
        margin-top: 148px;
    }
    ._loading_overlay_wrapper {
        margin-top: 148px;
    }
}

.main-container {
    background-color: #E8E8E8;
    border-radius: 15px;
    /*min-height: 100vh;*/
    /*margin: 10px 10px;
    min-width:79%;*/
}

.top-header{
    background-color: #fff;
    border-radius: 15px 15px 0 0;
    padding: 20px 10px;
    color: #373737;
}

@media screen and (min-width: 200px) and (max-width: 1062px) {
    .dashboard-top-header {
        display: none !important;
    }
    .top-summary {
        flex: 0 0 100%;
        width: 100%;
    }
}

@media screen and (max-width: 1062px) {
    .col-lg-3 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-lg-9 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.head-icon{
    color: #373737 !important;
    font-size: 60px !important;
}

.head-wrap{
    font-size: 30px;
}

.inside-content-wrapper{
    border: 1px solid #1D1D1D;
    border-radius: 20px;
    /*min-height: 100vh;*/
}

.inner-gray-content-wrap{
    background-color: #F3F3F3;
    border-radius: 20px;
    /*min-height: 100vh;*/
}

.inner-inner-content {
    /*height: 100vh;*/
    width: 100%;
    padding: 20px;
    backgroundColor: #f5f5f5;
    borderRadius: 10px;
}

.inner-gray-content-wrap .MuiDataGrid-root .MuiDataGrid-container--top [role=row], .css-11ur4lg-MuiDataGrid-root .MuiDataGrid-container--bottom {
    background: none !important;
}

.inner-gray-content-wrap .MuiDataGrid-root {
    border:none !important;
}

.inner-gray-content-wrap .MuiDataGrid-main {
    overflow: visible !important;
}

.inner-gray-content-wrap .MuiBox-root {
    height: auto !important;
}

.custom-search {
    width: 265px;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px !important;
    padding-bottom: 10px;
}

.custom-search .MuiOutlinedInput-root {
    padding: 0;
    height: 100%;
    border-radius: 30px !important;
}

.custom-search .MuiOutlinedInput-notchedOutline {
    border-color: #E4E4E4;
}

.custom-search .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #E4E4E4;
}

.custom-search .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #E4E4E4;
}

.custom-search .MuiInputAdornment-root {
    padding: 0 8px;
    background-color: #FFFFFF;
    border-right: 1px solid #E4E4E4;
}

.MuiOutlinedInput-input{
    padding: 5.5px 14px !important;
}

.search-icon {
    color: #353535;
}

.loadbalancer-table-container {
    width: 100%;
    overflow-x: scroll;
    overflow-y: auto;
}

.loadbalancer-table tr:first-child th {
    position:sticky;
    top:0;
    background: #333;
    inset-block-start: 0;
    position: -webkit-sticky;
    background-color: #fff;
}

.sticky-header {
    position: sticky;
    /*   top: 0; */
    inset-block-start: 0;
    z-index: 2;
    position: -webkit-sticky;
    /*background-color: white;
    z-index: 100;
    border-bottom: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 12px !important;
    font-weight: 500;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;*/
}

.loadbalancer-table td {
    color: #4D4D4D !important;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: left;
    align-content: center;
}

.loadbalancer-table th{
    padding: 12px !important;
    font-weight: 600;
    font-family: "Helvetica", "Arial", "Liberation Sans", "Noto Sans", sans-serif;
}

.loadbalancer-table td {
    padding: 0 12px !important;
    color: #4D4D4D !important;
    font-family: "Helvetica", "Arial", "Liberation Sans", "Noto Sans", sans-serif;
}

.loadbalancer-table::-webkit-scrollbar {
    height: 8px;
}

.loadbalancer-table::-webkit-scrollbar-track {
    background: #e0e0e0;
}

.loadbalancer-table::-webkit-scrollbar-thumb {
    background: #888;
}

.loadbalancer-table::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.manage-subclient-table-container th{
    padding: 12px !important;
    font-weight: 600;
    font-family: "Helvetica", "Arial", "Liberation Sans", "Noto Sans", sans-serif;
}

.manage-subclient-table-container td {
    padding: 2px 12px !important;
    font-size: 12px;
    color: #4D4D4D !important;
    font-family: "Helvetica", "Arial", "Liberation Sans", "Noto Sans", sans-serif;
}

.manage-subclient-table-container::-webkit-scrollbar {
    height: 8px;
}

.manage-subclient-table-container::-webkit-scrollbar-track {
    background: #e0e0e0;
}

.manage-subclient-table-container::-webkit-scrollbar-thumb {
    background: #888;
}

.manage-subclient-table-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/*NOTIFICATION*/
.day {
    color: #373737;
    font-size: 11px;
    font-weight: 600;
}

.success{
    background-color: #FFFFFF;
    border-radius: 4px;
    border-left: 5px solid #3B9B24;
}

.warning{
    background-color: #FFFFFF;
    border-radius: 4px;
    border-left: 5px solid #E7A343;
}

.info{
    background-color: #FFFFFF;
    border-radius: 4px;
    border-left: 5px solid #2F8FBF;
}

.error-hd {
    color: #FF1616;
    font-size: 16px;
    font-weight: 700;
}

.success-hd {
    color: #3B9B24;
    font-size: 12px;
}

.warning-hd {
    color: #E7A343;
    font-size: 12px;
}

.info-hd {
    color: #2F8FBF;
    font-size: 12px;
}

.success-sub-hd {
    color: #373737;
    font-weight:600;
    font-size: 12px;
}

.success-description {
    color:#9FAAB5;
    font-size: 10px;
}

.success-icon {
    color: #3B9B24;
}

.warning-icon {
    color: #E7A343;
}

.info-icon {
    color: #2F8FBF;
}

.date{
    color: #9FAAB5;
    font-size: 10px;
    text-align: right;
}

.close-notification{
    color: #353535 !important;
    font-size: 12px !important;
    float: right;
    cursor: pointer;
}

.notification-modal-icon {
    width: 2em !important;
    height: 2em !important;
}

.notification-modal-description{
    color: #9FAAB5 !important;
    font-size: 12px !important;
}

.notification-card {
    cursor: pointer;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.notification-card.unread {
    background-color: #ffffff;
}

.notification-card.read {
    background-color: #eeeeee;
}

.validation-error-card {
    background-color: #ffffff;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    border-left: 5px solid #FF1616;
}

.no-notifications {
    padding: 70px 0;
}

/*Profile settings*/
.p-form {
    font-size: 10px;
    color: #373737;
}

.input-padding {
    padding-top: 24px;
}
/* Container for upload input */
.upload-container {
    border-bottom: 1px solid #373737;
    padding-bottom: 5px;
    width: 100%;
    position: relative;
}

/* Styling for the placeholder text field (Add File URL) */
.file-url-input {
    border: none;
    background-color: transparent;
    color: #8F9095;
    font-size: 14px;
    outline: none;
    width: 70%;
    padding-left: 10px;
}

/* Upload label button */
.upload-label {
    color: #373737 !important;
    cursor: pointer;
    font-size: 14px;
    float: right;
}

/* Styling for the upload icon */
.upload-icon {
    margin-right: 5px;
    font-size: 20px;
    color: #373737;
}

/* Hide the actual file input */
.file-upload-input {
    display: none;
}

/* Hover effect for the upload label */
.upload-label:hover {
    text-decoration: underline;
}

/* Profile Input Border */

.input-wrapper {
    position: relative;
}

.password-icon {
    position: absolute;
    right: 0;
    top: 25%;
    transform: translateY(-50%);
    cursor: pointer;
    padding-right: 5px;
}

.profile-input {
    border-bottom: 1px solid #373737;
}

.profile-settings .profile-input {
    border-bottom: 1px solid #373737;
}

.profile-input .MuiInput-underline:before{
    borderBottomColor: #373737;
}

.profile-input .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid #373737;
}

.profile-input .MuiInput-underline:after {
    border-bottom: 2px solid #373737;
}

.profile-settings input {
    font-size: 13px;
}

.reset-password-button {
    padding-top: 45px;
}

.reset-password-button .btn-upload {
    padding: 5px;
    font-size: 14px;
}

.profile-initial {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background-color: #464646;
    color: #fff;
    font-size: 40px;
}

.profile-initial-sidenav {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    display: flex;
    padding: 3px;
    color: #fff;
    text-align: center;
    font-size: 35px;
    align-items: center;
    justify-content: center;
    background: #000;
}

.profile-initial-sidenav-collapse {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
    padding:3px;
    background: #000;
    display: flex;
    color: #fff;
    font-size: 25px;
    align-items: center;
    justify-content: center;
}

/*upload button*/
.btn-upload {
    background-color: #fff !important;
    border-radius: 8px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.btn-upload:hover {
    background-color: #f0f0f0 !important;
    box-shadow: inset 0 2px 4px rgba(206, 206, 206, 0.2);
}

.btn-upload:focus {
    outline: none;
    box-shadow: inset 0 2px 4px rgba(206, 206, 206, 0.2);
}

.btn-upload:active {
    background-color: #e0e0e0 !important;
    box-shadow: inset 0 2px 4px rgba(206, 206, 206, 0.2);
}

/*delete button*/
.btn-delete {
    background-color: #E7E7E7 !important;
    border-radius: 8px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.btn-delete:hover {
    background-color: #dcdcdc !important;
    box-shadow: inset 0 2px 4px rgba(206, 206, 206, 0.2);
}

.btn-delete:focus {
    outline: none;
    box-shadow: inset 0 2px 4px rgba(206, 206, 206, 0.2);
}

.btn-delete:active {
    background-color: #cfcfcf !important;
    box-shadow: inset 0 2px 4px rgba(206, 206, 206, 0.2);
}

/* Profile Picture Styling */
.image-preview {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 1);
}

.profile-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

/* Profile Info Text Styling */
.profile-info .profile-title {
    font-size: 20px;
    color: #373737;
    margin: 0;
}

.profile-info .profile-subtext {
    font-size: 12px;
    color: #8F9095;
    margin: 0;
}

/*DASHBOARD*/
.top-summary .box {
    border: 1px solid #E3E3E3;
    border-radius: 25px;
}

@media screen and (min-width: 200px) and (max-width: 991px) {
    .top-summary .box {
        margin-bottom: 15px;
    }
}

.icon-container1{
    width: 62px;
    height: 62px;
    background-color: #9AF9F9;
    border-radius: 18px;
}

.icon-container2{
    width: 62px;
    height: 62px;
    background-color: #E4CCFF;
    border-radius: 18px;
}

.icon-container3{
    width: 62px;
    height: 62px;
    background-color: #FBDDC3;
    border-radius: 18px;
}

.icon-container4 {
    width: 62px;
    height: 62px;
    background-color: #C7FFC6;
    border-radius: 18px;
}

.summary-icon {
    font-size: 30px;
}

.summary-text {
    font-size: 12px;
    color: #8F9095;
}

.summary-count {
    font-size: 25px;
    color: #373737;
    font-weight: 600;
}

.link-summary {
    color: #373737 !important;
    text-decoration:none !important;
}

.link-summary:hover {
    text-decoration:none !important;
}

@media screen and (min-width: 200px) and (max-width: 580px) {
    .summary-count {
        font-size: 15px;
    }
}

.inside-content-wrapper-dashboard {
    border: 1px solid #1D1D1D;
    border-radius: 20px;
}

.header-dashboard-sub {
    font-size: 22px;
    font-weight: 600;
    color: #373737;
}

.sub-topic {
    font-weight: 200;
}

/*.prod-summary-card1 {
    background-color: #84B898;
    color: #fff;
    margin-right: 15px;
}

.prod-summary-card2 {
    background-color: #A686C4;
    color: #fff;
    margin-right: 15px;
}

.prod-summary-card3 {
    background-color: #E9AE79;
    color: #fff;
    margin-right: 15px;
}

.prod-summary-card4 {
    background-color: #36C8C8;
    color: #fff;
}*/

.prod-summary-card1 { background-color: #84B898; }
.prod-summary-card2 { background-color: #A686C4; }
.prod-summary-card3 { background-color: #E9AE79; }
.prod-summary-card4 { background-color: #36C8C8; }

.prod-summary-card1,
.prod-summary-card2,
.prod-summary-card3 {
    color: #fff;
    margin-right: 10px;
    padding: 0 10px;
    text-align: center;
}

.prod-summary-card4 {
    color: #fff;
    padding: 0 10px;
    text-align: center;
}

@media (max-width: 768px) {
    .prod-summary-card1, .prod-summary-card2, .prod-summary-card3, .prod-summary-card4 {
        margin-right: 0;
        margin-bottom: 15px;
    }
}

@media (min-width: 768px) {
    .product-summary-card {
        display: flex;
        flex-wrap: nowrap;
    }
    .prod-summary-card1, .prod-summary-card2, .prod-summary-card3, .prod-summary-card4 {
        margin-right: 15px;
        flex: 1;
    }
    .prod-summary-card4 {
        margin-right: 0;
    }
}

.candidate-mismatch-summary-container {
    background-color: #010101;
    border-radius: 20px;
    color: #fff;
}

.candidate-missmatch-hd {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
}

.search-bar-container {
    display: flex;
    align-items: center;
    border-radius: 42px;
    border: 1px solid #373A42;
    position: absolute;
    height: 38px;
    padding: 0 6px;
    transition: width 0.3s ease-in-out;
    cursor: pointer;
    right: 0;
}

/* Expanded state */
.search-bar-container.expanded {
    width: 250px; /* Width when expanded */
}

/* Collapsed state */
.search-bar-container.collapsed {
    width: 38px; /* Width when collapsed */
}

/* Example media query for smaller screens */
@media screen and (max-width: 768px) {
    .search-bar-container.expanded {
        width: 150px; /* Adjusted width for smaller screens */
    }

    .search-bar-container.collapsed {
        width: 38px; /* Adjusted width for smaller screens */
    }
}


.search-input {
    flex: 1;
    margin-left: 10px;
    font-size:14px;
    color: #373737;
}

.verifiaction-calender .MuiButtonBase-root {
    cursor: default;
    border: 1px solid #373A42;
    border-radius: 35px;
    padding: 0 20px;
}

.verifiaction-calender .MuiInputBase-input{
    min-width: 88px !important;
}

.verification-history-table-in {
    background-color: #F3F3F3 !important;
    border-radius: 20px !important;
}

.verification-history-table th {
    font-weight: 600;
    color: #373737;
}

.verification-history-table tr td {
    font-size: 12px !important;
    padding: 10px 16px;
}

.verifiaction-calender .react-datepicker-wrapper {
    width: 100px;
}

.pagination-container {
    display: flex;
    justify-content: end;
    margin-top:20px;
    background-color: #F3F3F3;
    border-radius: 45px;
    padding: 0 20px;
}

.pagination-container .MuiPagination-root {
    background-color: #F3F3F3;
    padding: 5px 30px;
    border-radius: 30px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.MuiButtonBase-root-MuiPaginationItem-root {
    border-radius: 20px !important;
}

.pagination-container li button {
    border-radius: 20px !important;
}

.success-bg {
    background-color: #47AA45;
    border-radius: 20px;
    color: #fff;
    padding: 2px 10px;
}

.error-bg {
    background-color: #FF1616;
    border-radius: 20px;
    color: #fff;
    padding: 2px 10px;
}

.error-error-bg {
    background-color: #d10c0c;
    border-radius: 20px;
    color: #fff;
    padding: 2px 10px;
}

.inprogress-bg {
    background-color: #007BFF;
    border-radius: 20px;
    color: #fff;
    padding: 2px 10px;
}

.inverstigation-bg {
    background-color: #CE9A2D;
    border-radius: 20px;
    color: #fff;
    padding: 2px 10px;
}

.usage-trends-container {
    background-color: #F3F3F3;
    border-radius: 20px;
    color: #373737;
}

.usage-trends-hd {
    font-size: 20px;
    font-weight: 600;
    color: #373737;
}

@media (max-width: 900px) {
    .custom-chart {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

/*Bar Chart*/
.bar-tooltip-cvr {
    padding: 5px 10px;
    background: white;
    color: black;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
}

.bar-tooltip-in {
    width: 12px;
    height: 12px;
    margin-right: 5px;
}

/*Pie Chart*/
.legend {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.legend-item {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff;
}

.legend-color-match {
    width: 25px;
    height: 20px;
    border-radius: 20%;
    display: inline-block;
    background-color: #47AA45;
}

.legend-color-mismatch {
    width: 25px;
    height: 20px;
    border-radius: 20%;
    display: inline-block;
    background-color: #E7A343;
}

.custom-pie-chart {
    /*margin-top:50px;*/
}

.custom-pie-chart .nivo-pie g path[stroke] {
    stroke: transparent !important;
    stroke-width: 0 !important;
    opacity: 0 !important;
}

.pie-chart-left {
    padding: 50px 0 0 0 !important
}

.pie-tootip-cvr {
    padding: 5px 10px;
    background: white;
    color: black;
    border-radius: 3px;
    display:flex;
    align-items: center;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.pie-tootip-in {
    width: 12px;
    height:12px;
    border-radius: 2px;
    margin-right: 5px;
}

/*Line chart*/
/*.line-date-picker-container {
    background-color: #fff;
    border-radius: 5px;
}*/

.usage-datepicker-to {
    margin: 0 8px;
}

.time-period-cvr {
    border-right: 1px solid #EAEAEA;
    padding: 6px 15px;
}

.line-datepicker-inside input{
    border: none;
    font-size: 12px;
    padding: 0 10px;
    max-width: 100px;
}

.line-date-picker-container {
    background: #fff;
    padding: 0 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.line-datepicker-inside {
    display: flex;
    align-items: center;
    padding: 0 15px;
}

.custom-datepicker {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 13px;
    width: auto;
}

.calendar-icon {
    margin-left: 8px;
    color: #6c757d;
    cursor: pointer;
}

.react-datepicker-wrapper {
    display: flex;
    align-items: center;
    width: auto; /* Prevents the date picker from stretching */
}

.react-datepicker__input-container {
    display: flex;
    align-items: center;
}

.react-datepicker__input-container input {
    border: none;
    outline: none;
    width: auto;
}

.line-no-data {
    text-align: center;
    padding-top: 100px;
    color: #666;
}

.line-chart-tooltip {
    background: white;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

@media screen and (min-width: 200px) and (max-width: 588px) {
    .time-period-cvr {
        display: none !important;
    }
    .line-datepicker-inside {
        padding: 4px 0 !important;
    }
    .line-date-picker-container {
        padding: 0 5px !important;
    }
    .usage-datepicker-to {
        margin: 0 !important;
    }
}


/*CONFIGURAION*/
.configuration th {
    font-weight: 600;
}

.configuration .MuiOutlinedInput-input {
    padding: 2px 4px !important
}

/*BILLING CONSOLE*/
.total-consumption-title {
    color: #8F9095;
    font-size: 14px;
    line-height: 2;
}

.total-consumption-count {
    color: #010101;
    font-weight: 600;
    font-size: 40px;
}
@media screen and (min-width: 200px) and (max-width: 767px) {
    .payment-history-container {
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 200px) and (max-width: 690px) {
    .billing-top-header {
        display: none !important;
    }
    .billing-top-summary {
        flex: 0 0 100%;
        width: 100%;
    }
}

.billing-top-summary .box {
    border: 1px solid #E3E3E3;
    border-radius: 25px;
}

@media screen and (min-width: 200px) and (max-width: 991px) {
    .billing-top-summary .box {
        margin-bottom: 15px;
    }
}

.plan-details-container {
    background: linear-gradient(to bottom, #FFFFFF 0%, #F2F4FF 100%);
    border-radius: 20px;
    padding: 10px 16px;
}

.plan-sub-heading {
    color: #8F9095;
    font-size: 12px;
}

.plan-details {
    color: #373737;
    font-size: 20px;
}

.plan-details-inner {
    line-height: 2;
}

.payment-purchase-heading {
    padding: 22px 16px 10px 16px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.dropdown-container {
    position: relative;
    display: inline-block; /* Ensures that the dropdown container is inline */
}

.line-date-picker-container {
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 50%;
    right: 50%;
    transform: translateX(-50%); /* Centers the dropdown */
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 5px 5px;
    width: 200px;
    display: none; /* Default to hidden */
    z-index: 1000;
}

.dropdown-menu.show {
    display: block; /* Only show when the state `isOpen` is true */
}

.filter-search-input {
    width: 100%;
    padding: 5px 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-left: 0;
    flex: 1;
    font-size:14px;
    color: #373737;
}

.filter-search-input:focus  {
    border: 1px solid #373737
}

.filter-search-input:hover  {
    border: 1px solid #373737
}

.filter-search-input:active  {
    border: 1px solid #373737
}

.filter-search-input:valid  {
    border: 1px solid #373737
}

@media (max-width: 720px) {
    .responsive-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .responsive-container > * {
        margin-bottom: 10px; /* Space between elements */
        width: 100%; /* Full width for each item */
    }

    .responsive-container .line-date-picker-container {
        justify-content: flex-start;
    }
}

/*SUPPORT*/
.support-form {
    line-height: 4;
}


/*TRIAL CENTER*/
.trial-icatch-icon {
    color: #373737 !important;
    width: 3rem !important;
}
.video-container {
    background-color: #fff;
    padding: 10px;
}
.video-record-image {
    width: 100%;
}

.video-record-image-modal {
    width: 100%;
    height: auto;
}

.error-validation {
    width: 1.5em !important;
    height: 1.5em !important;
}

/* Styling for OR divider */
.or-divider {
    display: flex;
    align-items: center;
    margin: 0 20px; /* Adjust the margin to your needs */
}

/* Styling for the lines */
.or-divider .line {
    width: 65px; /* Length of the line */
    height: 1px;
    background-color: #373737; /* Color of the line */
    margin: 0 10px; /* Spacing around the OR text */
}

/* Styling for the OR text */
.or-divider .or-text {
    font-size: 14px;
    font-weight: bold;
    color: #373737; /* Color of OR text */
    padding: 0 10px;
}

.button-group {
    margin-top: 20px;
}

.record-btn {
    background-color: #84B898;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    padding: 10px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.record-btn:hover {
    background-color: #4a9a69;
    color: #fff;
}

.record-btn:focus {
    outline: none;
    box-shadow: none;
}

.save-video:focus {
    outline: none;
    box-shadow: none;
}

.choose-btn {
    background-color: #fff;
    color: #373737;
    font-size: 15px;
    font-weight: 400;
    padding: 10px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.choose-btn:hover {
    background-color: #c4c4c4;
    color: #373737;
}

.choose-btn:focus {
    outline: none;
    box-shadow: none;
}

.btn-modal-save {
    background-color: #0075F3;
    color: #fff;
    font-weight: 400;
    width: 100%;
    padding: 4px 0;
}

.btn-modal-cancel {
    background-color: #FF1616;
    color: #fff;
    font-weight: 400;
    width: 100%;
    padding: 4px 0;
}

.btn-modal-save:hover {
    background-color: #0065D0FF;
    transform: translateY(-2px);
    color: #fff;
}

.btn-modal-cancel:hover {
    background-color: #D30101FF;
    transform: translateY(-2px);
    color: #fff;
}

.btn-modal-save:active {
    background-color: #298FFFFF;
    transform: translateY(0);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.2);
    color: #fff;
}

.btn-modal-cancel:active {
    background-color: #FC4F4FFF;
    transform: translateY(0);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.2);
    color: #fff;
}

.btn-modal-save:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 117, 243, 0.5);
    color: #fff;
}

.btn-modal-cancel:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(255, 22, 22, 0.5);
    color: #fff;
}

.interview-round-hd {
    background-color: #84B898;
    color: #fff;
    font-size: 19px;
    padding: 6px 10px;
    font-weight: 200;
    margin-bottom: 4px;
}

.upload-target-container {
    background-color: #fff;
    padding: 20px 8px;
}

.start-verification-container {
    background-color: #fff;
    padding: 50px 130px;
    align-content: center;
}

.success-check {
    color: #47AA45;
    font-size: 20px;
    width: 2em !important;
    height: 2em !important;
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn{
    background-color: #cecece !important;
}

.continue-btn {
    background-color: #b75c79;
    color:#fff;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.02857em;
    line-height: 1.75;
    min-width: 64px;
    padding: 6px 13px;
    border-color: #b75c79;
    outline: none;
}

.continue-btn:hover {
    background-color: #9f4663;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    color: #f0f0f0;
}

.continue-btn:active {
    background-color: #943b58;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.2);
    transform: scale(0.95);
}

.continue-btn primary:focus {
    color: #fff;
    background-color: #9f4663;
    border-color: #943b58;
    outline: none;
}

.remove-btn {
    background-color: #ffffff80;
    color: #373737;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.02857em;
    line-height: 1.75;
    min-width: 64px;
    padding: 6px 13px;
}

.remove-btn:hover {
    background-color: rgba(213, 213, 213, 0.5);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    color: #373737;
}

.remove-btn:active {
    background-color: rgba(213, 213, 213, 0.5);
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.2);
    transform: scale(0.95);
    color: #373737;
}

/*CANDIDATE LIST*/

.candidate-list-table a{
    font-size: 12px;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.87);
    text-decoration: none;
}

.candidate-list-table a:hover{
    text-decoration: none;
    color: #0056b3;
}

.candidate-list-table .MuiButtonBase-root-MuiIconButton-root:hover {
    background-color: transparent !important;
}

/*LOADBALANCER CONFIGURATION*/
.loadbalancer-table tbody tr td{
    font-size: 12px !important;
}

.queue-lable {
    color: rgba(0, 0, 0, 0.6) !important;
    font-family: "Helvetica", "Arial", "Liberation Sans", "Noto Sans", sans-serif;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 1.4375em !important;
    letter-spacing: 0.00938em !important;
}

.config-input input {
    font-size: 12px !important;
}

.queue-type .MuiFormControlLabel-label {
    font-size: 12px !important;
}

.ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    display: inline-block;
}

/*default style*/
.on-select:hover {
    outline: none;
    box-shadow: none;
}

.on-select:focus {
    outline: none;
    box-shadow: none;
}

.btn.disabled, .btn:disabled{
    border:none;
}

@media screen and (min-width: 200px) and (max-width: 455px) {
    .rc-pagination-total-text {
        display: none;
    }
}

@media screen and (min-width: 200px) and (max-width: 520px) {
    .css-55h4yx-MuiFormControl-root-MuiTextField-root {
        width: 200px !important;
    }
}
