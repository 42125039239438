.f-10{
    font-size: 10px !important;
}
.f-11{
    font-size: 11px !important;
}
.f-12{
    font-size: 12px !important;
}
.f-13{
    font-size: 13px !important;
}
.f-14{
    font-size: 14px !important;
}
.f-15{
    font-size: 15px !important;
}
.f-16{
    font-size: 16px !important;
}
.f-17{
    font-size: 17px !important;
}
.f-18{
    font-size: 18px !important;
}
.f-19{
    font-size: 19px !important;
}
.f-20{
    font-size: 20px !important;
}
.f-21{
    font-size: 21px !important;
}
.f-22{
    font-size: 22px !important;
}
.f-23{
    font-size: 23px !important;
}
.f-24{
    font-size: 24px !important;
}
.f-25{
    font-size: 25px !important;
}
.f-30{
    font-size: 30px !important;
}
.f-35{
    font-size: 35px !important;
}
.f-40{
    font-size: 40px !important;
}
.f-45{
    font-size: 45px !important;
}
.f-50{
    font-size: 50px !important;
}
.f-60{
    font-size: 60px !important;
}

/*Text Align*/
.text-left{
    text-align: left;
}
.text-center{
    text-align: center;
}
.text-right{
    text-align: right;
}
.text-justify {
    text-align: justify;
}

.font-normal{
    font-weight: normal !important;
}
.font-bold{
    font-weight: 500;
}
.font-bolder{
    font-weight: 700;
}

/*Brand Text Color*/
.c-black {
    color: #000000;
}
.c-white {
    color: #ffffff;
}
.c-common-black {
    color: #373737;
}
.c-orange {
    color: #E7A343;
}
.c-green {
    color: #47AA45;
}

.c-red {
    color: red;
}

.c-blue {
    color: #0075F3;
}

.c-pink {
    color: #b75c79;
}

.cursor-pointer {
    cursor: pointer;
}

.no-wrap{
    white-space: nowrap;
}
