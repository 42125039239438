/*.loading-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); !* Semi-transparent background *!
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; !* Ensure it's on top of all content *!
}

.loading-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 300px;
}

.progress {
    width: 100%;
    background-color: #f3f3f3; !* Background color of the progress bar container *!
    border-radius: 5px;
    overflow: hidden;
    margin-top: 15px;
}

.progress-bar {
    height: 15px;
    background-color: green; !* Changed to green *!
    transition: width 0.6s ease; !* Smooth transition for the width *!
}*/

.loading-modal {
    /*background-color: rgba(0, 0, 0, 0.1); !* A lighter background so it blends in with the form *!*/
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; /* Keep it low to avoid overlap with other content */
    margin-top: 20px; /* Add some margin to push it below the form */
}

.loading-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    /*width: 300px;*/
    width: 100%;
}

.progress {
    width: 100%;
    background-color: #f3f3f3; /* Background color of the progress bar container */
    border-radius: 5px;
    overflow: hidden;
    margin-top: 15px;
}

.progress-bar {
    height: 15px;
    background-color: green; /* Progress bar color */
    transition: width 0.6s ease; /* Smooth transition for the width */
}

.validated-badge {
    display: inline-flex;
    align-items: center;
    margin-left: 8px;
    background-color: #47AA45;
    color: #fff;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    top: 2px;
}

.error-modal .modal-content {
    width: fit-content;
}
