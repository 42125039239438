.swal-icon--info {
    border-color: #E7A343;
    width: 50px;
    height: 50px;
}

.swal-icon--info:before {
    width: 5px;
    height: 25px;
    bottom: 17px;
    border-radius: 2px;
    margin-left: -2px;
}

.swal-icon--info:after, .swal-icon--info:before {
    background-color: #E7A343;
}

.swal-icon--info:after {
    width: 7px;
    height: 7px;
    top: 35px;
}

.swal-button {
    background-color: #b75c79;
    border: none; /* Remove default border */
    color: white;
    transition: transform 0.2s ease, background-color 0.2s ease;
}

.swal-button:hover {
    background-color: #b75c79 !important;
    transform: scale(1.05);
}

.swal-button:active {
    background-color: #b75c79;
    transform: scale(0.95);
}

.swal-button:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(183, 92, 121, 0.8);
    background-color: #b75c79;
}

.swal-button:focus-visible {
    background-color: #b75c79; /* Keep focus color consistent */
    box-shadow: 0 0 5px rgba(183, 92, 121, 0.8); /* Match hover effect */
}

.swal-button.btn-success {
    background-color: #28a745;
    color: white;
}

.swal-button.btn-success:hover {
    background-color: #218838;
    transform: scale(1.05);
}

.swal-button.btn-success:active {
    background-color: #1e7e34;
    transform: scale(0.95);
}

.swal-button.btn-success:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(40, 167, 69, 0.5);
}

.swal-button.btn-error {
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.swal-button.btn-error:hover {
    background-color: #c82333;
    transform: scale(1.05);
}

.swal-button.btn-error:active {
    background-color: #bd2130;
    transform: scale(0.95);
}

.swal-button.btn-error:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(220, 53, 69, 0.5);
}

.swal-text-center .swal-text,
.swal-text-center .swal-title {
    text-align: center;
}
